import { useState, useEffect } from 'react';

export const useOnScreen = (ref) => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		return false;
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const [isIntersecting, setIntersecting] = useState(false);
		// eslint-disable-next-line
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			observer.observe(ref.current);
			// Remove the observer as soon as the component is unmounted
			return () => {
				observer.disconnect();
			};
		});
		return isIntersecting;
	}
};
