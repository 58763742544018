import type { FC } from 'react';
import React, { useState, useCallback, useEffect, useRef, forwardRef } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import Blanket from '@atlaskit/blanket';
import type { PopupComponentProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import FilterIcon from '@atlaskit/icon/core/filter';
import { token } from '@atlaskit/tokens';
import { N0, N60A } from '@atlaskit/theme/colors';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreIcon from '@atlaskit/icon/utility/show-more-horizontal';

import { useSSRPlaceholderReplaceIdProp, LoadableLazy } from '@confluence/loadable';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { MenuButton } from './PresentationalComponents';
import type { SpaceViewsSort } from './useSpaceViewsOptions';
import { useOnScreen } from './useOnScreen';

const SpaceViewsMenuContentLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceViewsMenuContent" */ './SpaceViewsMenuContent'
			)
		).SpaceViewsMenuContent,
});

const useLazyOptionClickedAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireSpaceViewsOptionClickedAnalytics,
);

const useLazyTriggerClickedAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireSpaceViewsTriggerClickedAnalytics,
);

const i18n = defineMessages({
	menuTooltipForContent: {
		id: 'space-views.space.menu.tooltip.content',
		defaultMessage: 'Change view',
		description: "tooltip for the '...' menu shown next to the pages link in the sidebar",
	},
});

type MenuProps = {
	spaceId?: string;
	isWhiteboardBetaEnabled: boolean;
};

export type SetSelection = (option: SpaceViewsSort) => void;

export type PopupProps = {
	selection: string;
	setSelection: SetSelection;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const CustomPopupContainerComponent = styled.div({
	zIndex: 1000,
	backgroundColor: token('elevation.surface.overlay', N0),
	boxShadow: token('elevation.shadow.overlay', `0px 8px 12px 0px ${N60A}`),
	borderRadius: '3px',
	'&:focus': {
		outline: 'none',
	},
});

export const MenuComponent: FC<MenuProps & PopupProps & WrappedComponentProps> = ({
	selection,
	setSelection,
	intl,
	isWhiteboardBetaEnabled,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [isOpen, setIsOpen] = useState(false);
	const isNav4Enabled = useIsNav4Enabled();

	const handleClose = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const ref = useRef<HTMLDivElement>(null);

	const isVisible = useOnScreen(ref);

	useEffect(() => {
		if (!isVisible) {
			if (isOpen) {
				setIsOpen(false);
			}
		}
	}, [isVisible, isOpen]);

	const fireOptionClickedAnalytics = useLazyOptionClickedAnalytics(createAnalyticsEvent, selection);
	const setAndClose = useCallback(
		(option: any) => {
			setSelection(option);
			setIsOpen(false);
			void fireOptionClickedAnalytics(option);
		},
		[setSelection, fireOptionClickedAnalytics],
	);

	const fireTriggerClickedAnalytics = useLazyTriggerClickedAnalytics(
		createAnalyticsEvent,
		selection,
	);
	const onTriggerClick = useCallback(() => {
		setIsOpen((o) => !o);
		void fireTriggerClickedAnalytics();
	}, [fireTriggerClickedAnalytics]);

	const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
		({ children, ...props }, ref) => (
			<CustomPopupContainerComponent {...props} ref={ref}>
				{children}
			</CustomPopupContainerComponent>
		),
	);

	return (
		<div ref={ref} data-vc="space-views-menu" {...ssrPlaceholderIdProp}>
			{isOpen && (
				<Blanket
					isTinted={false}
					shouldAllowClickThrough={!isOpen}
					onBlanketClicked={handleClose}
					testId="space-views-menu-blanket"
				/>
			)}
			<Popup
				isOpen={isOpen}
				onClose={handleClose}
				content={(props) => (
					<SpaceViewsMenuContentLoader
						selection={selection}
						setSelection={setAndClose}
						intl={intl}
						{...props}
					/>
				)}
				popupComponent={CustomPopupContainer}
				trigger={(triggerProps) => (
					<>
						<Tooltip
							content={<FormattedMessage {...i18n.menuTooltipForContent} />}
							position={isWhiteboardBetaEnabled ? 'top' : 'bottom'}
							hideTooltipOnMouseDown
						>
							{isNav4Enabled ? (
								<IconButton
									{...triggerProps}
									icon={ShowMoreIcon}
									onClick={onTriggerClick}
									isSelected={isOpen}
									label={intl.formatMessage(i18n.menuTooltipForContent)}
									spacing="compact"
									appearance="subtle"
								/>
							) : (
								<MenuButton
									{...(triggerProps as any)}
									appearance="subtle"
									iconBefore={<FilterIcon label="" spacing="spacious" />}
									onClick={onTriggerClick}
									isSelected={isOpen}
									isOpen={isOpen}
									aria-label={intl.formatMessage(i18n.menuTooltipForContent)}
									isWhiteboardBetaEnabled={isWhiteboardBetaEnabled}
								/>
							)}
						</Tooltip>
					</>
				)}
				placement="bottom-start"
				shouldRenderToParent
			/>
		</div>
	);
};

export const SpaceViewsMenu = injectIntl(MenuComponent);
